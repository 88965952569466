/**
 * Video preloader
 */

import type { FunctionComponent, JSX } from 'preact'
import { createContext } from 'preact'
import { useCallback, useContext, useEffect, useState } from 'preact/hooks'

import { fetchMedia } from '../../utils/media.ts'

import video6KangurSrc from '../GameIdleVideo/videos/animacja_6_kangur-h.264.mp4'
import video6MisSrc from '../GameIdleVideo/videos/animacja_6_mis-h.264.mp4'
import video6MyszSrc from '../GameIdleVideo/videos/animacja_6_mysz-h.264.mp4'
import animacja_2_2 from '../../pages/GameScores/videos/animacja_2_2-h.264.mp4'
import animacja_4_2 from '../../pages/GameScores/videos/animacja_4_2-h.264.mp4'
import animacja_5_3 from '../../pages/GameScores/videos/animacja_5_3-h.264.mp4'
import animacja_1_mis_konfetti_2 from '../../pages/GameScores/videos/animacja_1_mis_konfetti_2-h.264.mp4'
import animacja_2_kangur_rewelacja_konfetti_2 from '../../pages/GameScores/videos/animacja_2_kangur_rewelacja_konfetti_2-h.264.mp4'
import animacja_7_mis_dobrze from '../../pages/GameScores/videos/animacja_7_mis_dobrze-h.264.mp4'
import animacja_8_mysz_rewelacja_konfetti_2 from '../../pages/GameScores/videos/animacja_8_mysz_rewelacja_konfetti_2-h.264.mp4'
import animacja_9_kangur_dobrze from '../../pages/GameScores/videos/animacja_9_kangur_dobrze-h.264.mp4'
import animacja_10_kangur_kiepsko from '../../pages/GameScores/videos/animacja_10_kangur_kiepsko-h.264.mp4'
import animacja_11_mis_srednio_2 from '../../pages/GameScores/videos/animacja_11_mis_srednio_2-h.264.mp4'
import animacja_12_mysz_srednio from '../../pages/GameScores/videos/animacja_12_mysz_srednio-h.264.mp4'
import animacja_13_mysz_kiepsko from '../../pages/GameScores/videos/animacja_13_mysz_kiepsko-h.264.mp4'

/**
 * API
 */
type Api = (video: string) => Blob | null

/**
 * Context object
 */
const VideoContext = createContext<Api | null>(null)

/**
 * Hook
 */
export function usePreloadedVideo(): Api {
  const value = useContext(VideoContext)

  if (!value) {
    throw new Error('Video Provider Context has not been provider')
  }

  return value
}

const videoUrls: Array<string> = [
  video6KangurSrc,
  video6MisSrc,
  video6MyszSrc,
  animacja_2_2,
  animacja_4_2,
  animacja_5_3,
  animacja_1_mis_konfetti_2,
  animacja_2_kangur_rewelacja_konfetti_2,
  animacja_7_mis_dobrze,
  animacja_8_mysz_rewelacja_konfetti_2,
  animacja_9_kangur_dobrze,
  animacja_10_kangur_kiepsko,
  animacja_11_mis_srednio_2,
  animacja_12_mysz_srednio,
  animacja_13_mysz_kiepsko,
]

/**
 * Provider
 */
export const VideoProvider: FunctionComponent<{
  fallback?: JSX.Element
}> = ({ children, fallback = null }) => {
  const [videosMap, setVideosMap] = useState<Map<string, Blob | null> | null>(null)

  useEffect(() => {
    const fetchAbortController = new AbortController()

    const entriesPromises = videoUrls
      // Unique
      .filter((currentValue, index, arr) => arr.indexOf(currentValue) === index)
      // Available
      .filter((currentValue): currentValue is string => currentValue !== null)
      // Entry
      .map(async (videoUrl): Promise<[string, Blob | null]> => {
        try {
          const response = await fetchMedia(videoUrl, fetchAbortController.signal)
          const blob = await response.blob()

          return [videoUrl, blob]
        } catch {
          return [videoUrl, null]
        }
      })

    Promise.all(entriesPromises).then((videoEntries) => setVideosMap(new Map(videoEntries)))

    // Stop fetches
    return () => fetchAbortController.abort('unmount')
  }, [])

  const api: Api = useCallback((key) => videosMap?.get(key) ?? null, [videosMap])

  return <VideoContext.Provider value={api}>{videosMap ? children : fallback}</VideoContext.Provider>
}
