import backgroundMusic from '../assets/sounds/coding-night-112186-v9-mono.mp3'
import signInAudio from '../assets/sounds/Welcome-hero-3.mp3'
import ovation1_1 from '../assets/sounds/Owacje-1_1.mp3'
import ovation1_2 from '../assets/sounds/Owacje-1_2.mp3'
import ovation2 from '../assets/sounds/Owacje-2.mp3'
import thatWasGreat from '../assets/sounds/That-Was-Great_9.mp3'
import jingle1_1 from '../assets/sounds/Jingle_1_1.mp3'
import jingle1_2 from '../assets/sounds/Jingle_1_2.mp3'
import jingle1_3 from '../assets/sounds/Jingle_1_3.mp3'
import jingle2_2 from '../assets/sounds/Jingle_2_2.mp3'
import jingle2_3 from '../assets/sounds/Jingle_2_3.mp3'
import jingle3_3 from '../assets/sounds/Jingle_3_3.mp3'
import whereAreYou3 from '../assets/sounds/Where-Are-You_3.mp3'
import yourMove4 from '../assets/sounds/Your-Move_4.mp3'
import areYouReady1 from '../assets/sounds/Are-You-Ready_1.mp3'

export const AUDIO = {
  // Events
  EVENT_SESSION_START: null,
  EVENT_SESSION_END: null,
  EVENT_PLAYER_SIGN_IN: signInAudio,
  EVENT_PLAYER_SIGN_OUT: null,
  EVENT_GAME_CREDITS: null,
  EVENT_GAME_START: null, // Has native sound
  EVENT_GAME_READY: null,
  EVENT_GAME_SCORES: null, // Has native sound
  EVENT_INFO_NO_CREDITS: null, // Has native sound
  // Pages
  PAGE_CONFIGURATION: backgroundMusic,
  PAGE_GLOBAL_SCORES: jingle3_3,
  PAGE_SESSION_END: [ovation1_1, ovation1_2, ovation2],
  PAGE_GAME_IDLE_1: [whereAreYou3, jingle2_2],
  PAGE_GAME_IDLE_2: [yourMove4, jingle1_3],
  PAGE_GAME_IDLE_3: [areYouReady1, jingle1_3],
  PAGE_GAME_SCORES_DISPLAYED_400: jingle1_1,
  PAGE_GAME_SCORES_DISPLAYED_600: jingle1_2,
  PAGE_GAME_SCORES_DISPLAYED_800: jingle2_3,
  PAGE_GAME_SCORES_DISPLAYED_999: [thatWasGreat, jingle2_3],
} as const

// wszystkie warianty wylistowac wraz z jinglami a pozniej zrobic pary i randomowo grac
