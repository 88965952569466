import type { FunctionComponent } from 'preact'

import './Message.css'

type MessageProps = {
  text: string
  error?: boolean
}

export const Message: FunctionComponent<MessageProps> = ({ text, error }) => {
  return (
    <section className="bx-page bx-page--message">
      {error && (
        <>
          <h2 className="bx-page-title">{'Upsss,'}</h2>
          <p className="bx-page--message__text">{'coś poszło nie tak :('}</p>
        </>
      )}

      <p className="bx-page--message__text">{text}</p>
    </section>
  )
}
