import type { FunctionComponent } from 'preact'
import clsx from 'clsx'

import './Icon.css'

/**
 * Icon/ Sign/ Logo Mark
 */
export const Icon: FunctionComponent<{ logomark?: boolean }> = ({ logomark = false }) => (
  <svg
    className={clsx(`bx-icon`, {
      'bx-icon--logomark': logomark,
    })}
    xmlns="http://www.w3.org/2000/svg"
  >
    <use xlinkHref="#bx-icon-symbol" width="100%" height="100%" />
  </svg>
)
