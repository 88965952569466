/**
 * [Multi track]{@link https://github.com/mdn/webaudio-examples/tree/main#multi-track}
 * [Gain Node]{@link https://developer.mozilla.org/en-US/docs/Web/API/GainNode}
 * {@link https://developer.chrome.com/blog/autoplay/#web-audio}
 *
 * Souce -> GainNode -> Destination
 */
import type { FunctionComponent, JSX } from 'preact'
import { createContext } from 'preact'
import { useCallback, useContext, useEffect, useMemo, useState } from 'preact/hooks'

import { AUDIO } from '../../constants/Audio.ts'
import { fetchMedia } from '../../utils/media.ts'

type AudioProps = string | null

/**
 * API
 */
type Api = (audio: AudioProps, options?: Omit<AudioBufferSourceOptions, 'buffer'>) => Promise<AudioBufferSourceNode | null>

/**
 * Context object
 */
const AudioMixerContext = createContext<Api | null>(null)

/**
 * Hook
 */
export function useAudioMixer(): Api {
  const value = useContext(AudioMixerContext)

  if (!value) {
    throw new Error('Audio Mixer Context has not been provided')
  }

  return value
}

/** Preload audio URLs */
const audioUrls: Array<string | null> = [
  AUDIO.EVENT_SESSION_START,
  AUDIO.EVENT_SESSION_END,
  AUDIO.EVENT_PLAYER_SIGN_IN,
  AUDIO.EVENT_PLAYER_SIGN_OUT,
  AUDIO.EVENT_GAME_CREDITS,
  AUDIO.EVENT_GAME_READY,
  AUDIO.PAGE_GAME_SCORES_DISPLAYED_400,
  AUDIO.PAGE_GAME_SCORES_DISPLAYED_600,
  AUDIO.PAGE_GAME_SCORES_DISPLAYED_800,
  ...AUDIO.PAGE_GAME_SCORES_DISPLAYED_999,
  AUDIO.PAGE_CONFIGURATION,
  ...AUDIO.PAGE_SESSION_END,
  AUDIO.PAGE_GLOBAL_SCORES,
  ...AUDIO.PAGE_GAME_IDLE_1,
  ...AUDIO.PAGE_GAME_IDLE_2,
  ...AUDIO.PAGE_GAME_IDLE_3,
]

/**
 * Provider
 */
export const AudioMixerProvider: FunctionComponent<{
  /** Master volume level */
  masterVolume?: number
  /** Fallback component until sounds are loaded */
  fallback?: JSX.Element
}> = ({ children, masterVolume = 0.5, fallback = null }) => {
  // Audio buffers map
  const [audioBuffersMap, setAudioBuffersMap] = useState<Map<string, AudioBuffer | null> | null>(null)

  // Create Audio Context
  const audioCtx: AudioContext = useMemo(() => new AudioContext(), [])

  // Create a gain node and set it's gain value
  const gainNode: GainNode = useMemo(() => {
    const gainNode = new GainNode(audioCtx, { gain: masterVolume })

    gainNode.connect(audioCtx.destination)

    return gainNode
  }, [audioCtx, masterVolume])

  // Preload audio buffers
  useEffect(() => {
    const fetchAbortController = new AbortController()

    const entriesPromises = audioUrls
      // Unique
      .filter((currentValue, index, arr) => arr.indexOf(currentValue) === index)
      // Available
      .filter((currentValue): currentValue is string => currentValue !== null)
      // Entry
      .map(async (audioUrl): Promise<[string, AudioBuffer | null]> => {
        try {
          const response = await fetchMedia(audioUrl, fetchAbortController.signal)
          const arrayBuffer = await response.arrayBuffer()
          const audioBuffer = await audioCtx.decodeAudioData(arrayBuffer)

          return [audioUrl, audioBuffer]
        } catch {
          return [audioUrl, null]
        }
      })

    Promise.all(entriesPromises).then((soundEntries) => setAudioBuffersMap(new Map(soundEntries)))

    return () => {
      fetchAbortController.abort('unmount')
      audioCtx.close()
    }
  }, [audioCtx])

  // Play API
  const api: Api = useCallback(
    async (audio, options) => {
      const audioBuffer = audio ? audioBuffersMap?.get(audio) : null

      if (!audioBuffer) {
        return null
      }

      // Check if context is in suspended state (autoplay policy)
      if (audioCtx.state === 'suspended') {
        // Note: Promise may be suspended by autoplay policy
        await audioCtx.resume()
      }

      // Create a buffer
      const trackSource = new AudioBufferSourceNode(audioCtx, {
        buffer: audioBuffer,
        ...options,
      })

      trackSource.connect(gainNode)
      trackSource.start(0, 0)

      trackSource.addEventListener('ended', () => trackSource.disconnect(gainNode))

      return trackSource
    },
    [audioBuffersMap, audioCtx, gainNode]
  )

  return <AudioMixerContext.Provider value={api}>{audioBuffersMap ? children : fallback}</AudioMixerContext.Provider>
}
