import type { FunctionComponent } from 'preact'

import type { ItemContentProps } from '../../Inactivity.tsx'
import { Logo } from '../../../../components/Logo/Logo.tsx'
import { QrCode } from '../../../../components/QrCode/QrCode.tsx'

import AppleAppStoreBadgeImageUrl from './images/apple-store-badge--pl.svg'
import GooglePlayBadgeImageUrl from './images/google-play-badge--pl.svg'

import './Invitation.css'

/**
 * Inactivity invitation content
 */
export const Invitation2ItemContent: FunctionComponent<ItemContentProps> = ({ appUrl }) => (
  <>
    <Logo />

    <QrCode value={appUrl} />

    <a className="bx-invitation-get-app-link" href={appUrl.href} target="store">
      <img className="bx-invitation-get-app-badge" src={GooglePlayBadgeImageUrl} alt="Google Play" />
      <img className="bx-invitation-get-app-badge" src={AppleAppStoreBadgeImageUrl} alt="Apple App Store" />
    </a>

    <p className="bx-text-emphasis bx-color-primary bx-inactivity-item__text">
      Pobierz aplikację
      <br />
      i odbierz
      <br />
      2x więcej kredytów!
    </p>
  </>
)
