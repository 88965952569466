/**
 * Execute task in full intervals (ie. full hours)
 * Returns function to stop
 */
export function setModInterval(task: () => Promise<void> | void, delay: number): () => void {
  let nextCheckTimeoutId: number

  doDelayedCheck(delay)

  return () => window.clearTimeout(nextCheckTimeoutId)

  function doDelayedCheck(delay: number): void {
    nextCheckTimeoutId = window.setTimeout(
      () => {
        task()

        doDelayedCheck(delay)
      },
      delay - (Date.now() % delay)
    )
  }
}
