import AbstractEventSourceOpenError from './AbstractOpenError.ts'

/**
 * Event source retriable error
 */
export default class EventSourceOpenRetriableError extends AbstractEventSourceOpenError {
  public name = 'EventSourceOpenRetriableError'

  constructor(response: Response, message: string = 'Event Source open response with client error') {
    super(response, message)

    Object.setPrototypeOf(this, EventSourceOpenRetriableError.prototype)
  }
}
