type DateRange = {
  startDate: Date
  endDate: Date
}

/**
 * Get month date range for reference date
 */
export function getMonthDateRange(referenceDate: Date): DateRange {
  const startDate = new Date(referenceDate.getFullYear(), referenceDate.getMonth(), 1)
  const endDate = new Date(referenceDate.getFullYear(), referenceDate.getMonth() + 1, 1)

  return {
    startDate,
    endDate,
  }
}
