import type { FunctionComponent } from 'preact'

import { Icon } from '../Icon/Icon.tsx'

import './Logo.css'

export const Logo: FunctionComponent = () => (
  <div className="bx-logo">
    <Icon logomark />
    <div className="bx-logo__logotype">{'World Boxer\nChampionship'}</div>
  </div>
)
