/**
 * Event source closed server-side (retriable)
 */
export default class EventSourceCloseError extends Error {
  public name = 'EventSourceCloseError'

  constructor(message: string = 'Event Source closed') {
    super(message)

    Object.setPrototypeOf(this, EventSourceCloseError.prototype)
  }
}
