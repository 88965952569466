import type { FunctionComponent } from 'preact'
import { useEffect, useMemo } from 'preact/hooks'
import clsx from 'clsx'

import type { ScheduleItem } from '../Inactivity.tsx'
import { useAudioMixer } from '../../../components/AudioMixer/AudioMixer.tsx'
import { AUDIO } from '../../../constants/Audio.ts'

import './Item.css'

/**
 * Item content wrapper
 */
export const Item: FunctionComponent<ScheduleItem & { isCurrent: boolean }> = ({ Content, isCurrent, playSound = false }) => {
  const playAudio = useAudioMixer()
  const appUrl: URL = useMemo(() => new URL('get-player-app', import.meta.env.VITE_API_URL), [])

  useEffect(() => {
    if (playSound && isCurrent) {
      playAudio(AUDIO.PAGE_GLOBAL_SCORES)
    }
  }, [playSound, isCurrent, playAudio])

  return (
    <article className={clsx('bx-page bx-inactivity-item', isCurrent && 'bx-inactivity-item--current')}>
      <Content appUrl={appUrl} isCurrent={isCurrent} />
    </article>
  )
}
