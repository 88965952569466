/**
 * Fetch url
 * Decode to ArrayBuffer or Blob as you wish
 * @throws {Error}
 */
export async function fetchMedia(url: string, signal?: AbortSignal): Promise<Response> {
  const response = await fetch(url, { signal })

  if (!response.ok) {
    throw new Error(`HTTP error, status = ${response.status}`)
  }

  return response
}
