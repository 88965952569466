import type { FunctionComponent, JSX } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import { useSystemStore } from '../../store/systemStore.ts'

/**
 * Hydrated system state provider
 * Render children only when state has been hydrated, otherwise fallback component
 * @link https://docs.pmnd.rs/zustand/integrations/persisting-store-data#how-can-i-check-if-my-store-has-been-hydrated
 * @link https://github.com/pmndrs/zustand/issues/586
 */
export const SystemStateProvider: FunctionComponent<{
  startUrl: URL
  fallback?: JSX.Element
}> = ({ children, startUrl, fallback = null }) => {
  const [hydrated, setHydrated] = useState<boolean>(useSystemStore.persist.hasHydrated)
  const setSerialNumberFromUrl = useSystemStore((state) => state.setSerialNumberFromUrl)

  useEffect(() => {
    const unsubHydrate = useSystemStore.persist.onHydrate(() => setHydrated(false))

    const unsubFinishHydration = useSystemStore.persist.onFinishHydration(() => setHydrated(true))

    setHydrated(useSystemStore.persist.hasHydrated())

    return () => {
      unsubHydrate()
      unsubFinishHydration()
    }
  }, [])

  // Read serial number from search params
  useEffect(() => {
    hydrated && setSerialNumberFromUrl(startUrl)
  }, [hydrated, startUrl, setSerialNumberFromUrl])

  return <>{hydrated ? children : fallback}</>
}
