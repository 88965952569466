/**
 * Wrapper for TypeError: Failed to fetch
 */
export default class ConnectError extends Error {
  public name = 'ConnectError'

  constructor(
    public request: Request,
    options?: ErrorOptions
  ) {
    super('No connection', options)

    Object.setPrototypeOf(this, ConnectError.prototype)
  }
}
