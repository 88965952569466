import { devtools } from 'zustand/middleware'
import { create } from 'zustand'

import { getMonthDateRange } from '../utils/date.ts'

export type SessionToken = string

export type Credits = number

export type GameScore = number

export type PlayerScoresStats = {
  max: GameScore | null
}

export type EndBoxingResult = Array<{ value: number }>

export type Player = {
  email: string
  givenName: string
  familyName: string
  nickName: string
}

interface Game {
  sessionToken: SessionToken | null
  setSessionToken: (sessionToken: SessionToken | null) => void

  credits: Credits
  setCredits: (credits: Credits) => void

  gameScore: GameScore | null
  /** Set or reset game score and related state */
  setGameScore: (gameScore: GameScore | null) => void

  isGameScoreDisplayed: boolean
  setIsGameScoreDisplayed: (isGameScoreDisplayed: boolean) => void

  player: Player | null
  setPlayer: (player: Player | null) => void
  fetchPlayer: (id: number, accessToken: string | null) => Promise<void | Error>

  playerScoresStats: PlayerScoresStats
  fetchPlayerScoresStats: (playerId: number, accessToken: string | null) => Promise<void | Error>

  playerScoresIsNewMax: boolean

  endBoxingResult: EndBoxingResult | null
  setEndBoxingResult: (endBoxingResult: EndBoxingResult | null) => void

  /** Reset state after power-on */
  resetGame: () => void
}

export const useGameStore = create<Game>()(
  devtools(
    (set) => ({
      sessionToken: null,
      setSessionToken: (sessionToken) => set({ sessionToken }),

      credits: 0,
      setCredits: (credits) => set({ credits }),

      gameScore: null,
      // TODO: Reset stats on delayed sign-outs
      setGameScore: (gameScore) =>
        set((state) => ({
          gameScore,
          // Note: Might remove for case when game/start event is missing
          isGameScoreDisplayed: false,
          playerScoresStats: recalcPlayerScoreStats(state.playerScoresStats, gameScore),
          // Detect new max for signed-in players
          playerScoresIsNewMax: state.player !== null && gameScore !== null && gameScore > (state.playerScoresStats.max ?? 0),
        })),

      isGameScoreDisplayed: false,
      setIsGameScoreDisplayed: (isGameScoreDisplayed) => set({ isGameScoreDisplayed }),

      player: null,
      setPlayer: (player) => set({ player }),
      fetchPlayer: async (id, accessToken) => {
        // Note: API doesn't emit sign in event to non-authorized request so check below is not neccessary
        if (!accessToken) {
          return
        }

        const url = new URL(`player/${id}`, import.meta.env.VITE_API_URL)

        return fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => (response.ok ? response.json() : null))
          .then((player: Player | null) => set({ player }))
      },

      playerScoresStats: { max: null },
      fetchPlayerScoresStats: async (playerId, accessToken) => {
        if (!accessToken) {
          return
        }

        const monthDateRange = getMonthDateRange(new Date())

        // Current month
        const url = new URL(`/game/scores/player/${playerId}`, import.meta.env.VITE_API_URL)

        url.searchParams.set('startDate', monthDateRange.startDate.toISOString())
        url.searchParams.set('endDate', monthDateRange.endDate.toISOString())

        return fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }).then((response) => {
          response.ok &&
            void response.json().then((playerScoresStats: PlayerScoresStats) =>
              // Compute stats from current state for sign-ins during gameplay
              // This should not be required as endpoint responds with fresh data
              set((state) => ({
                playerScoresStats: recalcPlayerScoreStats(state.playerScoresStats, playerScoresStats.max),
              }))
            )
        })
      },

      playerScoresIsNewMax: false,

      endBoxingResult: null,
      setEndBoxingResult: (endBoxingResult) => set({ endBoxingResult }),

      resetGame: () =>
        set({
          sessionToken: null,
          credits: 0,
          gameScore: null,
          isGameScoreDisplayed: false,
          player: null,
          playerScoresStats: { max: null },
          playerScoresIsNewMax: false,
          endBoxingResult: null,
        }),
    }),
    { name: document.title, store: 'GameStore' }
  )
)

/**
 * Recalculate player score stats
 * Reset when game is over
 */
function recalcPlayerScoreStats(playerScoresStats: PlayerScoresStats, gameScore: GameScore | null): PlayerScoresStats {
  return {
    ...playerScoresStats,
    max: gameScore ? Math.max(gameScore, playerScoresStats.max ?? 0) : null,
  }
}
