import AbstractEventSourceOpenError from './AbstractOpenError.ts'

/**
 * Event source fatal error
 */
export default class EventSourceOpenFatalError extends AbstractEventSourceOpenError {
  public name = 'EventSourceOpenFatalError'

  constructor(response: Response, message: string = 'Event Source open response with server error') {
    super(response, message)

    Object.setPrototypeOf(this, EventSourceOpenFatalError.prototype)
  }
}
