import type { FunctionComponent } from 'preact'

import { Message } from '../Message/Message.tsx'

import './Init.css'

/**
 * Initialization page
 */
export const Init: FunctionComponent = () => {
  return (
    <section className="bx-page">
      <Message text={'Uruchamianie…'} />
    </section>
  )
}
