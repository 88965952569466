import type { ComponentType, FunctionComponent } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'

import { SECONDS } from '../../constants/DateTime.ts'
import { Item } from './Item/Item.tsx'
import { LogoItemContent } from './ItemContent/Logo.tsx'
import { Invitation1ItemContent } from './ItemContent/Invitation/Invitation1.tsx'
import { Invitation2ItemContent } from './ItemContent/Invitation/Invitation2.tsx'
import { GlobalScores, withGameScoresProps } from './ItemContent/GloablScores/GameScores.tsx'
import { WarningItemContent } from './ItemContent/Warning/Warning.tsx'

import './Inactivity.css'

export type ItemContentProps = {
  appUrl: URL
  isCurrent: boolean
}

export type ScheduleItem = {
  /** Content component  */
  Content: ComponentType<ItemContentProps>
  /** Item duration in seconds */
  duration?: number
  /** Play sound on reveal */
  playSound?: boolean
}

// Item playback durations
const DURATION = {
  // Invitation
  LONG: 15 * SECONDS,
  // Game score, Warning
  MEDIUM: 10 * SECONDS,
  // Logo
  SHORT: 5 * SECONDS,
} as const

/**
 * Inactivity page
 */
export const Inactivity: FunctionComponent = () => {
  // prettier-ignore
  const schedule: ScheduleItem[] = useMemo((): ScheduleItem[] => [
    { Content: withGameScoresProps(GlobalScores, { filters: {}, groupByTerminalCity: true }) },
    { Content: withGameScoresProps(GlobalScores, { filters: {} }), playSound: true },
    { Content: withGameScoresProps(GlobalScores, { filters: {}, groupByTerminalCity: true }) },
    { Content: Invitation1ItemContent, duration: DURATION.LONG },
    { Content: withGameScoresProps(GlobalScores, { filters: { ageCategory: 'Youth' }, subCaption: 'Do 16 lat: Kadet' }), playSound: true },
    { Content: withGameScoresProps(GlobalScores, { filters: { ageCategory: 'Junior' }, subCaption: '16-18 lat: Junior' }) },
    { Content: withGameScoresProps(GlobalScores, { filters: { ageCategory: 'Open' }, subCaption: 'Powyżej 18 lat: Open' }) },
    { Content: withGameScoresProps(GlobalScores, { filters: {}, groupByTerminalCity: true })},
    { Content: Invitation2ItemContent, duration: DURATION.LONG },
    { Content: withGameScoresProps(GlobalScores, { filters: { sex: 'F' }, subCaption: 'Kobiety' }), playSound: true },
    { Content: withGameScoresProps(GlobalScores, { filters: { sex: 'M' }, subCaption: 'Mężczyźni' }) },
    { Content: withGameScoresProps(GlobalScores, { filters: {}, groupByTerminalCity: true }) },
    { Content: LogoItemContent, duration: DURATION.SHORT },
    { Content: Invitation1ItemContent, duration: DURATION.LONG },
    { Content: LogoItemContent, duration: DURATION.SHORT },
    { Content: Invitation2ItemContent, duration: DURATION.LONG },
    { Content: LogoItemContent, duration: DURATION.SHORT },
    { Content: WarningItemContent, duration: DURATION.MEDIUM },
    { Content: LogoItemContent, duration: DURATION.SHORT },
  ], [])

  const [scheduleIndex, setScheduleIndex] = useState<number>(0)

  // Toggle current index
  useEffect(() => {
    const { duration = DURATION.MEDIUM } = schedule[scheduleIndex]

    // Schedule index update
    const timer = window.setTimeout(() => setScheduleIndex((scheduleIndex + 1) % schedule.length), duration)

    return () => window.clearTimeout(timer)
  }, [schedule, scheduleIndex])

  return (
    <section className="bx-page bx-page-inactivity">
      {schedule.map((scheduleItem, index) => (
        <Item key={index} {...scheduleItem} isCurrent={index === scheduleIndex} />
      ))}
      {/* <div class="bx-page-title">{'Ranking'}</div> */}
    </section>
  )
}
