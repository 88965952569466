import { useEffect } from 'preact/hooks'

const isWakeLockSupported = 'wakeLock' in navigator

/**
 * Wake lock hook
 * @see https://web.dev/wakelock/
 * @see https://developer.mozilla.org/en-US/docs/Web/API/WakeLock
 */
export const useWakeLock = () => {
  // On mount/ unmount
  useEffect(() => {
    if (!isWakeLockSupported) {
      return
    }

    let wakeLock: WakeLockSentinel | null = null

    requestWakeLock()

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      releaseWakeLock()
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }

    /**
     * Request new wake lock when visibile
     */
    function handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        requestWakeLock()
      }
    }

    /**
     * Request wake lock
     */
    async function requestWakeLock() {
      // Release previous wake lock
      releaseWakeLock()

      try {
        wakeLock = await navigator.wakeLock.request('screen')
      } catch (error) {
        // No-op, probably low battery
        return
      }

      // Reset wake lock sentinel on release (on document.visibilityState: hidden)
      wakeLock.addEventListener('release', () => (wakeLock = null), { once: true })
    }

    /**
     * Release wake lock
     */
    function releaseWakeLock() {
      if (wakeLock) {
        wakeLock.release()
      }
    }
  }, [])
}
