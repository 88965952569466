import type { FunctionComponent } from 'preact'
import Router, { type RouterOnChangeArgs, getCurrentUrl, exec, route } from 'preact-router'

import { useState, useEffect, useRef } from 'preact/hooks'
import { useShallow } from 'zustand/react/shallow'
import clsx from 'clsx'
import type { EventSourceMessageTypes } from './interfaces/EventSourceMessage.d.ts'
import { type EventSourceMessage, fetchEventSource, EventStreamContentType } from '@microsoft/fetch-event-source'

import { ConnectError, EventSourceOpenFatalError, EventSourceOpenRetriableError, EventSourceCloseError } from './utils/Error/index.ts'

import { useCursorShow } from './hooks/useCursorShow.ts'
import { useWakeLock } from './hooks/useWakeLock.ts'
import { useIsPageStatePassive } from './hooks/useIsPageStatePassive.ts'
import { UpdateHandler } from './components/UpdateHandler/UpdateHandler.tsx'
import { AppInfoPanel } from './components/AppInfoPanel/AppInfoPanel.tsx'
import { AudioMixerProvider, useAudioMixer } from './components/AudioMixer/AudioMixer.tsx'
import { VideoProvider } from './components/VideoProvider/VideoProvider.tsx'
import { BackgroundVideo } from './components/BackgroundVideo/BackgroundVideo.tsx'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary.tsx'
import { SystemStateProvider } from './components/SystemStateProvider/SystemStateProvider.js'
import { useGameStore } from './store/gameStore.ts'
import { useSystemStore } from './store/systemStore.ts'
import { SECONDS } from './constants/DateTime.ts'

import { Init } from './pages/Init/Init.tsx'
import { Inactivity } from './pages/Inactivity/Inactivity.tsx'
import { SessionStart } from './pages/SessionStart/SessionStart.tsx'
import { GameStart } from './pages/GameStart/GameStart.tsx'
import { GameReady } from './pages/GameReady/GameReady.tsx'
import { GameScores } from './pages/GameScores/GameScores.tsx'
import { SessionEnd } from './pages/SessionEnd/SessionEnd.tsx'
import { InfoNoCredits } from './pages/InfoNoCredits/InfoNoCredits.tsx'
import { Message } from './pages/Message/Message.tsx'
import { Configuration } from './pages/Configuration/Configuration.tsx'
import { SignIn } from './pages/Sign-In/Sign-In.tsx'

import IconImage from './assets/images/icon.svg?react'

import { AUDIO } from './constants/Audio.ts'

import './App.css'

const App: FunctionComponent = () => {
  const playAudio = useAudioMixer()

  const [eventSourceReadyState, setEventSourceReadyState] = useState<number | null>(null)

  const serialNumber = useSystemStore((state) => state.serialNumber)
  const accessToken = useSystemStore((state) => state.accessToken)
  const setCurrentPage = useSystemStore((state) => state.setCurrentPage)

  const [setSessionToken, setPlayer, fetchPlayer, fetchPlayerScoresStats, setCredits, setGameScore, setIsGameScoreDisplayed, setEndBoxingResult, resetGame] =
    useGameStore(
      useShallow((state) => [
        state.setSessionToken,
        state.setPlayer,
        state.fetchPlayer,
        state.fetchPlayerScoresStats,
        state.setCredits,
        state.setGameScore,
        state.setIsGameScoreDisplayed,
        state.setEndBoxingResult,
        state.resetGame,
      ])
    )

  const isMachinePairedRef = useRef<boolean | null>(null)

  const sessionEndTimeoutId = useRef<number | null>(null)

  /**
   * Connect to event source with current serial number and access token
   */
  useEffect(() => {
    if (!serialNumber) {
      return
    }

    const url = new URL(`terminal/${serialNumber}/events`, import.meta.env.VITE_API_URL)
    const headers = new Headers({
      'Accept': EventStreamContentType,
      // ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      'X-Client-Version': import.meta.env.VITE_APP_VERSION,
    })

    const abortController = new AbortController()

    accessToken && url.searchParams.set('access-token', accessToken)

    // Note: The headers and signal RequestInit params are expected to be in fetchEventSource init
    const request = new Request(url, {
      method: 'GET',
      credentials: 'include',
    })

    setEventSourceReadyState(EventSource.CONNECTING)

    // Reference to last message
    let lastEventSourceMessage: EventSourceMessage | null = null

    // Custom logger for fetch
    const eventStreamTag = [`%c[EventStream]`, 'color: slateblue; font-weight: bold']

    fetchEventSource(request, {
      headers: Object.fromEntries(headers),
      signal: abortController.signal,

      // Do not disconnect on visibility change
      openWhenHidden: true,

      // Wrap TypeError
      async fetch(input, init) {
        const request = new Request(input, init)

        try {
          return await globalThis.fetch(request)
        } catch (typeError) {
          throw new ConnectError(request, { cause: typeError })
        }
      },

      /**
       * Open attempt
       * @throws {EventSourceFatalError}
       * @throws {EventSourceRetriableError}
       */
      async onopen(response) {
        if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
          setEventSourceReadyState(EventSource.OPEN)
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          throw new EventSourceOpenFatalError(response)
        } else {
          throw new EventSourceOpenRetriableError(response)
        }
      },

      /**
       * Error handler executed on:
       * - Error in handlers (onopen|onmessage|onclose)
       * - TypeError: Failed to fetch - [Re]connection failed
       * - TypeError: network error - Stream terminated (getBytes -> ReadableStreamDefaultReader.read) 'net::ERR_QUIC_PROTOCOL_ERROR 200 (OK)'
       * - After system/shutdown event with TypeError
       *
       * @throws {EventSourceFatalError} Rethrow to stop operation
       */
      onerror(error: ConnectError | EventSourceOpenFatalError | EventSourceOpenRetriableError | EventSourceCloseError | TypeError | Error) {
        // eslint-disable-next-line no-console
        console.info(...eventStreamTag, error)

        // Stop
        if (error instanceof EventSourceOpenFatalError) {
          setEventSourceReadyState(EventSource.CLOSED)

          throw error
        }

        // Change state when previous successful connection
        setEventSourceReadyState(EventSource.CONNECTING)

        // API restart
        if (lastEventSourceMessage?.event === 'system/shutdown') {
          return 15 * SECONDS
        }

        // Retry after longer backoff
        if (error instanceof EventSourceOpenRetriableError) {
          return 15 * SECONDS
        }

        // Retry after longer backoff
        if (error instanceof EventSourceCloseError) {
          return 10 * SECONDS
        }

        // Assume network error
        // @link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
        if (error instanceof ConnectError || error instanceof TypeError) {
          return 5 * SECONDS
        }

        // Retry after default backoff
        return
      },

      /**
       * Triggered on when connection is closed server-side
       * @throws {EventSourceCloseError} For automatic reconnection
       */
      onclose() {
        throw new EventSourceCloseError()
      },

      /**
       * Message handler
       * @note Unlike with native EventSource, id with a '' value is not persisted across messages
       */
      onmessage(eventSourceMessage) {
        // Set last
        lastEventSourceMessage = eventSourceMessage

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let data: any = undefined

        if (eventSourceMessage.data) {
          try {
            /** @throws {SyntaxError} */
            data = JSON.parse(eventSourceMessage.data)
          } catch (syntaxError) {
            // Noop
          }
        }

        switch (eventSourceMessage.event) {
          // Initial retry info
          case '':
            break

          // No-ops
          case 'system/shutdown':
          case 'stream/start':
          case 'stream/ping':
            break

          // Credits top-up event
          case 'game/credits':
            setCredits(data satisfies EventSourceMessageTypes['game/credits'])
            playAudio(AUDIO.EVENT_GAME_CREDITS)

            break

          // Session start event (no sound, there is one for game/credits)
          case 'session/start':
            setSessionToken(data.token satisfies EventSourceMessageTypes['session/start'])

            setPlayer(null)
            setGameScore(null)
            setEndBoxingResult(null)

            route('/session/start')

            break

          // Player sign-in event
          case 'player/sign-in':
            playAudio(AUDIO.EVENT_PLAYER_SIGN_IN)

            try {
              fetchPlayer(data.playerId satisfies EventSourceMessageTypes['player/sign-in']['playerId'], accessToken)
              fetchPlayerScoresStats(data.playerId satisfies EventSourceMessageTypes['player/sign-in']['playerId'], accessToken)
            } catch {
              // Continue regardless of error
            }

            break

          // Player sign-out event (no sound, there is one for game/scores)
          case 'player/sign-out':
            setPlayer(null)

            break

          // Game Start event
          case 'game/start':
            // Reset score displayed
            // Note: Also reset on LED screen ~2min after game/scores event
            setIsGameScoreDisplayed(false)

            playAudio(AUDIO.EVENT_GAME_START)

            route('/game/start')

            break

          // Game Ready event
          case 'game/ready':
            playAudio(AUDIO.EVENT_GAME_READY)

            route('/game/ready')

            break

          // Game Scores event
          case 'game/scores':
            setGameScore(data.value satisfies EventSourceMessageTypes['game/scores']['value'])
            setCredits(data.credits satisfies EventSourceMessageTypes['game/scores']['credits'])

            playAudio(AUDIO.EVENT_GAME_SCORES)

            route('/game/scores')

            break

          // Game Scores Displayed event
          case 'game/scores-displayed':
            setIsGameScoreDisplayed(true)

            break

          // Session end event
          case 'session/end':
            setEndBoxingResult(data satisfies EventSourceMessageTypes['session/end'])

            // Show last score before summary
            sessionEndTimeoutId.current = window.setTimeout(() => {
              setGameScore(null)
              playAudio(AUDIO.EVENT_GAME_CREDITS)

              route('/session/end')
            }, 10 * SECONDS)

            break

          // Machine paired event
          case 'machine/paired':
            if (isMachinePairedRef.current !== data) {
              route(data ? '/inactivity' : '/configuration')
            }

            isMachinePairedRef.current = data satisfies EventSourceMessageTypes['machine/paired']

            break

          // Info: No credits event
          case 'info/no-credits':
            route('/info/no-credits')

            break

          // Reset current game event
          case 'game/reset': {
            resetGame()

            // Note: Should reset on Signi-n which should be a modal
            const resettableGameRoutes: string[] = ['/session/start', '/game/start', '/game/ready', '/game/scores', '/session/end', '/info/no-credits']
            const isGameRoute = resettableGameRoutes.some((resettableGameRoute) => exec(getCurrentUrl(), resettableGameRoute, {}))

            // Reset route when in game
            if (isGameRoute) {
              route('/inactivity')
            }

            break
          }

          // Unknown event type
          default:
            break
        }
      },
    })
      // Error thrown from within onerror handler to stop reconnections
      .catch((_error) => {})

    // Note: Usually State immediately switcing to CONNECTING without a flash
    abortController.signal.addEventListener('abort', () => setEventSourceReadyState(EventSource.CLOSED))

    // Close event source for given serial number
    // Note: This resolves fetchEventSource promise, but doesn't trigger any handler (onclose|onerror)
    return () => abortController.abort()
  }, [serialNumber, accessToken]) // eslint-disable-line react-hooks/exhaustive-deps -- No need to specify stable store actions

  const handleRouteChange = (args: RouterOnChangeArgs) => {
    // Stop any redirections after a timeout
    sessionEndTimeoutId.current && window.clearTimeout(sessionEndTimeoutId.current)

    setCurrentPage(args.path === '/inactivity' ? 'inactivity' : null)
  }

  // Fatal error
  if (!serialNumber) {
    return <Message text={'Brakujący lub błędny parametr\n "serial-number" w adresie URL'} error />
  }

  // Connection states
  // Note: This prevents rendering Router component
  // TODO: Use modals
  switch (eventSourceReadyState) {
    case null:
      return <Init />

    case EventSource.CONNECTING:
      return <Message text="Trwa łączenie z serwerem API…" />

    case EventSource.CLOSED:
      return <Message text="Brak połączenia z serwerem API" error />

    case EventSource.OPEN:
    default:
      break
  }

  return (
    <Router onChange={handleRouteChange}>
      <Init path="/" />
      <Configuration path="/configuration" />
      <SignIn path="/sign-in" />
      <Inactivity path="/inactivity" />
      <SessionStart path="/session/start" />
      <GameStart path="/game/start" />
      <GameReady path="/game/ready" />
      <GameScores path="/game/scores" />
      <SessionEnd path="/session/end" />
      <InfoNoCredits path="/info/no-credits" />
    </Router>
  )
}

/**
 * Wrap app component by providers
 */
const AppWrapper: FunctionComponent<{ startUrl: URL; env: ImportMetaEnv }> = ({ startUrl, env }) => {
  const [showCursor, setElementRef] = useCursorShow()
  const isPageStatePassive = useIsPageStatePassive()

  useWakeLock()

  return (
    <main ref={setElementRef} className={clsx('bx-app', { 'bx-app--show-cursor': showCursor })}>
      <ErrorBoundary>
        <SystemStateProvider startUrl={startUrl} fallback={<Message text="Wczytywanie stanu…" />}>
          <UpdateHandler startUrl={startUrl} disableServiceWorker={env.VITE_APP_DISABLE_SW === 'true'} restartPending={<Message text={'Aktualizacja…'} />}>
            <AudioMixerProvider fallback={<Message text="Ładowanie dźwięków…" />}>
              <VideoProvider fallback={<Message text="Ładowanie animacji…" />}>
                <App />
              </VideoProvider>
            </AudioMixerProvider>
          </UpdateHandler>
          <AppInfoPanel />
        </SystemStateProvider>
      </ErrorBoundary>
      <BackgroundVideo pause={isPageStatePassive || showCursor} />
      {/* Symbols  */}
      <svg className="bx-app__svg-symbols" xmlns="http://www.w3.org/2000/svg">
        <IconImage id="bx-icon-symbol" />
      </svg>
    </main>
  )
}

export { AppWrapper as App }
