/**
 * Abstract event source error
 */
export default abstract class AbstractEventSourceOpenError extends Error {
  public name = 'AbstractEventSourceOpenError'

  constructor(
    public response: Response,
    message: string = 'Event Source open response with error'
  ) {
    super(message)

    // V8 only, doesn't prepend cause
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, new.target)
    }

    // Set the prototype explicitly on when compilation target is ES5.
    Object.setPrototypeOf(this, AbstractEventSourceOpenError.prototype)
  }
}
