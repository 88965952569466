import { FunctionComponent } from 'preact'

import { useSystemStore } from '../../store/systemStore.ts'

import './AppInfoPanel.css'

/**
 * App info panel
 * Visible on cursor movements
 */
export const AppInfoPanel: FunctionComponent = () => {
  const { VITE_APP_VERSION: appVersion, VITE_HOSTING_IS_PULL_REQUEST: isPullRequest } = import.meta.env

  const accessToken = useSystemStore((state) => state.accessToken)

  return (
    <dl className="bx-app__info">
      <dt>{'App version'}</dt>
      <dd>{appVersion}</dd>

      <dt>{'Pull request'}</dt>
      <dd>{isPullRequest}</dd>

      <dt>{'Access token'}</dt>
      <dd>
        <a href="/sign-in">{JSON.stringify(accessToken !== null)}</a>
      </dd>
    </dl>
  )
}
